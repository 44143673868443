import { Container, Stack, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { Colors } from '../../../assets/mui-styles/Colors';
import { deviceSelector } from '../../../entities/AuthSlice';
import { CalculatorTabs } from '../calculator-tabs/CalculatorTabs';
import { useSwitchCalculatorTab } from '../calculator-tabs/useSwitchCalculatorTab';
import { isBenefitsUpdatedSelector } from '../../../entities/ProjectSlice';
import UpdateNotification from '../../../widgets/update-notification/UpdateNotification';
import { useState } from 'react';
import { FetchStatusHandler } from './components/FetchStatusHandler';
import { LotEstimationBlock } from './components/LotEstimationBlock';
import { PriceEstimationBlock } from './components/PriceEstimationBlock';
import { FreeEstimationBlock } from './components/FreeEstimationBlock';

export const CalculatorBlock = ({
  propsForCalculatorByPrice,
  propsForCalculatorByLot,
  propsForCalculatorByFreeNew,
  fetchStatus,
  statusSetters,
  uniqueId,
  meetingId,
  isEqualizer,
}) => {
  const { activeTab, handleChangeActiveTab } = useSwitchCalculatorTab(uniqueId, isEqualizer, meetingId);
  const deviceInfo = useSelector(deviceSelector());
  const isBenefitsUpdated = useSelector(isBenefitsUpdatedSelector);
  // стейт для попапа отправки пдф по почте
  const [isEmailPopupForFreeOpen, setIsEmailPopupForFreeOpen] = useState(false);

  return (
    <>
      <FetchStatusHandler fetchStatus={fetchStatus} />
      <Container
        sx={{
          minWidth: '100%',
          backgroundColor: Colors.primary,
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        {isBenefitsUpdated && <UpdateNotification />}
        <StyledStack>
          <CalculatorTabs
            meetingId={meetingId}
            propsForCalculatorByLot={propsForCalculatorByLot}
            propsForCalculatorByPrice={propsForCalculatorByPrice}
            propsForCalculatorByFreeNew={propsForCalculatorByFreeNew}
            statusSetters={statusSetters}
            handleChangeActiveTab={handleChangeActiveTab}
            activeTab={activeTab}
            isEqualizer={isEqualizer}
            fetchStatus={fetchStatus}
          />

          {deviceInfo.device !== 'isMobile' && (
            <>
              <LotEstimationBlock
                propsForCalculatorByLot={propsForCalculatorByLot}
                fetchStatus={fetchStatus}
                meetingId={meetingId}
                isEqualizer={isEqualizer}
                activeTab={activeTab}
              />

              <PriceEstimationBlock
                propsForCalculatorByPrice={propsForCalculatorByPrice}
                fetchStatus={fetchStatus}
                meetingId={meetingId}
                activeTab={activeTab}
              />

              <FreeEstimationBlock
                propsForCalculatorByFreeNew={propsForCalculatorByFreeNew}
                fetchStatus={fetchStatus}
                activeTab={activeTab}
                isEmailPopupForFreeOpen={isEmailPopupForFreeOpen}
                setIsEmailPopupForFreeOpen={setIsEmailPopupForFreeOpen}
              />
            </>
          )}
        </StyledStack>
      </Container>
    </>
  );
};

const StyledStack = styled(Stack)({
  display: 'grid !important',
  gridTemplateColumns: 'repeat(2, minmax(300px, 1fr))',
  gap: '24px',
  alignItems: 'flex-start !important',
  justifyContent: 'space-between',
  width: '100%',
});
