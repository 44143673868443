import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearError, useResetPasswordMutation, errorStatus } from '../../../entities/AuthSlice';
import { validateEmail } from '../../../utils/utils';

export const useResetPasswordLogic = ({ setIsResetPasswordMode }) => {
  const [emailForReset, setEmailForReset] = useState('');
  const [isEmailForResetValid, setEmailForResetValid] = useState(false);
  const [isResetPasswordSuccessful, setResetPasswordSuccessful] = useState(false);
  const [resetPassword] = useResetPasswordMutation();
  const error = useSelector(errorStatus());
  const dispatch = useDispatch();

  const handleChangeEmailForReset = e => {
    setEmailForReset(e);
    setEmailForResetValid(validateEmail(e));
  };

  const handleCloseModals = () => {
    dispatch(clearError());
    setEmailForReset('');
    setEmailForResetValid(false);
    setIsResetPasswordMode(false);
  };

  const onSubmit = async email => {
    try {
      await resetPassword(email).unwrap();
      setResetPasswordSuccessful(true);
    } catch (e) {
      console.error('Ошибка при сбросе пароля:', e);
    }
  };

  useEffect(() => {
    dispatch(clearError());
  }, [emailForReset, dispatch]);

  return {
    emailForReset,
    isEmailForResetValid,
    isResetPasswordSuccessful,
    error,
    setEmailForReset,
    handleChangeEmailForReset,
    onSubmit,
    dispatch,
    handleCloseModals,
  };
};
