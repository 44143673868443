import { useState, useEffect } from 'react';
import { useDebouncedFunction } from '../../../hooks/useDebouncedFunction';

export const useGetGracePercent = uniqueId => {
  const storageName = `gracePercent${uniqueId}`;
  const [gracePercentForDisplay, setGracePercentForDisplay] = useState(1);
  const [gracePercent, setGracePercent] = useState(1);
  const setGracePercentDebounced = useDebouncedFunction(setGracePercent, 600);

  const onChangeGracePercent = percent => {
    setGracePercentForDisplay(percent);
    setGracePercentDebounced(percent);
    sessionStorage.setItem(storageName, percent);
  };

  useEffect(() => {
    const gracePercent = sessionStorage.getItem(storageName);
    if (gracePercent) {
      setGracePercentForDisplay(Number(gracePercent));
      setGracePercent(Number(gracePercent));
    }
  }, []);

  return {
    gracePercentForDisplay,
    gracePercent,
    onChangeGracePercent,
  };
};
