import { Box, Typography, Card, styled } from '@mui/material';
import { CustomSelect } from './CustomSelect';
import { EarlyPaymentCard } from './EarlyPaymentCard';
import { Colors } from '../../../assets/mui-styles/Colors';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const EarlyPaymentSection = ({
  earlyPaymentsType,
  onChangeEarlyPaymentsType,
  earlyPaymentOptions,
  onChangeEarlyPayment,
  earlyPaymentsForDisplay,
  createEmptyPayment,
  deletePayment,
  fetchStatus,
}) => {
  return (
    <StyledCard>
      <Typography variant='h3' fontSize='24px' fontWeight='500'>
        Рассчитать досрочное погашение
      </Typography>
      <Box width='30%'>
        <CustomSelect
          label='Что уменьшить?'
          handleChangeCurrentItem={onChangeEarlyPaymentsType}
          currentItem={{ value: earlyPaymentsType?.value, title: earlyPaymentsType?.title } || ''}
          items={earlyPaymentOptions}
        />
      </Box>
      {earlyPaymentsForDisplay?.map((payment, index) => (
        <EarlyPaymentCard
          key={payment?.id}
          payment={payment}
          index={index}
          deletePayment={deletePayment}
          onChangeEarlyPayment={onChangeEarlyPayment}
          fetchStatus={fetchStatus}
        />
      ))}
      <StyledAddPaymentButton onClick={createEmptyPayment}>
        <StyledAddCircleIcon />
        <Typography fontSize={14}>Добавить платеж</Typography>
      </StyledAddPaymentButton>
    </StyledCard>
  );
};

const StyledAddPaymentButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 16px 8px 12px',
  background: Colors.additional,
  width: 'fit-content',
  cursor: 'pointer',
});

const StyledAddCircleIcon = styled(AddCircleIcon)({
  width: '20px',
  height: '20px',
});

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  gap: '22px',
  background: Colors.additional,
  border: '1px solid',
  borderColor: Colors.superLightGrey,
  borderTopColor: 'transparent',
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: '30px 24px',
  ':last-child': {
    marginTop: '20px',
  },
});
