import { useEffect, useMemo, useState } from 'react';
import { fetchBenefits, fetchFlats, fetchMortgagesPrice } from './calculator-logic/dataFetchers';
import { useSelectBenefit } from './calculator-logic/useSelectBenefit';
import { useSelectBuilding } from './calculator-logic/useSelectBuilding';
import { useSelectFirstPayment } from './calculator-logic/useSelectFirstPayment';
import { useSelectFlat } from './calculator-logic/useSelectFlat';
import { useSelectProject } from './calculator-logic/useSelectProject';
import { useSelectTerm } from './calculator-logic/useSelectTerm';
import { useSetMinFirstPayment } from './calculator-logic/useSetMinFirstPayment';
import { useSwitchPercentOrSum } from './calculator-logic/useSetPercentOrSum';
import { useGetLotCalcData } from './calculator-logic/useGetLotCalcData';
import { DOMAIN_RELATIVE } from '../../utils/constants';
import { setBenefitsUpdateTime, setIsBenefitsUpdated } from '../../entities/ProjectSlice';
import { handleOpenModelWarning } from '../../entities/ComponentsSlice';
import { useDispatch } from 'react-redux';
import { changeOriginalBenefitsForSelect } from './utils/utils';

export const useCalculatorLogicForLot = (uniqueId, statusSetters, isEqualizer = false) => {
  const { handleSetErrorStatus, handleSetLoadedStatus, handleSetPendingStatus } = statusSetters;
  const isLotTab = true;
  const [flats, setFlats] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [executionCount, setExecutionCount] = useState(0);
  const [mortgagesList, setMortgagesList] = useState([]);
  const [isDefaultCalculation, setIsDefaultCalculation] = useState(true);
  const [priceWithMargin, setPriceWithMargin] = useState(0);
  const pathName = window?.location?.pathname || '';

  const { activeProject, handleChangeActiveProjectId, activeProjectId } = useSelectProject(uniqueId);
  const { handleChangeBuilding: handleChangeSection, building: section } = useSelectBuilding(`Section${uniqueId}`);
  const { handleChangeBuilding: handleChangeHouse, building: house } = useSelectBuilding(`House${uniqueId}`);
  const { handleChangeBuilding: handleChangeBulk, building: bulk } = useSelectBuilding(`Bulk${uniqueId}`);

  const [lastEventId, setLastEventId] = useState(() => localStorage.getItem('lastEventId'));

  const dispatch = useDispatch();

  const { flatsNumbers, flatNumber, handleChangeFlat, flat, lotSelectedFlat } = useSelectFlat(
    flats,
    bulks,
    { section, bulk, house },
    activeProject,
    uniqueId,
    isLotTab,
  );
  const benefits = useMemo(() => {
    let arr = mortgagesList.filter(item => {
      if (!item.rooms) {
        return true;
      }
      if (flat.rooms === 'studio' || flat.rooms === 4 || flat.rooms === 5) {
        return Number(item.rooms) === 3;
      }
      return Number(item.rooms) === flat.rooms;
    });

    if (house) {
      arr = arr.filter(item => !item.houseName || item.houseName === house);
    }

    return arr;
  }, [flat?.rooms, house, mortgagesList]);

  const { benefit, handleChangeBenefit, benefitIdentificator, benefitNotFound, clearBenefit } = useSelectBenefit(
    benefits,
    uniqueId,
  );

  const currentBenefit = useMemo(() => {
    if (isEqualizer) {
      return benefits.find(
        item => String(item?.name?.toLowerCase()?.trim()) === benefitIdentificator?.toLowerCase()?.trim(),
      );
    } else {
      return benefits.find(item => Number(item?.id) === Number(benefit?.id));
    }
  }, [benefits, benefitIdentificator]);

  const maxTerm = (isEqualizer ? currentBenefit?.mortgage?.maxPeriod : benefit?.maxYears) || 1;
  const minTerm = (isEqualizer ? currentBenefit?.mortgage?.minPeriod : benefit?.minYears) || 1;
  const flatPrice = useMemo(() => {
    if (flats.length > 0) {
      const flat = flats.find(item => {
        if (
          (!house || String(item.bulk.houseName) === house) &&
          (!bulk || String(item.bulk.name) === bulk) &&
          (!section || String(item.section.number) === section)
        ) {
          return String(item.id) === String(lotSelectedFlat?.id);
        }
        return false;
      });

      if (!flat) {
        return null;
      }
      return flat?.real_price;
    }
    return null;
  }, [bulk, flatNumber, flats, house, section, lotSelectedFlat]);

  const {
    initialPayment,
    firstPayment,
    handleChangeFirstPayment,
    handleChangeFirstPaymentCommitted,
    firstPaymentCommitted,
  } = useSelectFirstPayment(currentBenefit, flatPrice, uniqueId, statusSetters, isEqualizer);

  const minFirstPayment = (isEqualizer ? currentBenefit?.mortgage?.minPrice : initialPayment?.initialPaymentData) || 0;
  const maxFirstPayment = (isEqualizer ? currentBenefit?.mortgage?.maxPrice : initialPayment?.maxInitialPayment) || 1;

  const { term, handleChangeTerm, termCommitted, handleChangeTermCommitted } = useSelectTerm(
    uniqueId,
    isEqualizer ? currentBenefit?.mortgage?.maxPeriod : currentBenefit?.maxYears,
    activeProjectId,
  );
  const { handleSetMinFirstPayment, isSetMinFirstPaymentChecked } = useSetMinFirstPayment(
    minFirstPayment || firstPayment,
    firstPayment,
    handleChangeFirstPaymentCommitted,
  );
  const {
    lotCalcData: calcData,
    handleGetLotCalcData: handleGetCalcData,
    clearLotCalcData,
  } = useGetLotCalcData(
    { minFirstPayment, maxFirstPayment },
    termCommitted,
    firstPaymentCommitted,
    flatPrice,
    currentBenefit,
    statusSetters,
  );

  const { handleSwitchValue, isPercent, percentFirstPayment, handleChangePercentValue } = useSwitchPercentOrSum(
    handleChangeFirstPaymentCommitted,
    firstPayment,
    priceWithMargin,
  );

  const criteria = [
    flat?.id,
    benefitIdentificator,
    currentBenefit,
    termCommitted >= minTerm && termCommitted <= maxTerm,
    firstPaymentCommitted >= minFirstPayment && firstPaymentCommitted <= maxFirstPayment,
  ];

  useEffect(() => {
    let timeoutId;
    if (isEqualizer) {
      if (criteria.every(Boolean)) {
        timeoutId = setTimeout(() => {
          setExecutionCount(0);
        }, 1500);

        // если запрос выполняется больше 4 раз за 1500мс
        // то скорее всего начался бесконечный цикл
        // после 4 запроса показываем ошибку и сбрасываем расчеты
        // useEffect останавливается
        // количетсво попыток сбрасывается через setTimeout через 5000мс

        if (executionCount >= 4) {
          dispatch(
            handleOpenModelWarning({
              open: true,
              text: 'По этой программе недостаточно данных. Выберите другую.',
            }),
          );
          // сброс расчетов
          clearLotCalcData();
          setPriceWithMargin(0);
          // сброс активной программы
          clearBenefit();
          return;
        }

        handleSetPendingStatus();
        fetchBenefits({
          flatId: flat.id,
          benefitId: currentBenefit?.id,
          initialPayment: firstPaymentCommitted,
          loanTerm: termCommitted,
        })
          .then(result => {
            handleSetLoadedStatus();
            setMortgagesList([...result.benefits.cash, ...result.benefits.mortgage, ...result.benefits.installment]);
            setIsDefaultCalculation(false);
            // увеличиваем счетчик запросов
            setExecutionCount(executionCount + 1);
          })
          .catch(() => {
            handleSetErrorStatus('Error in mortgages');
          });
      } else if (flat && !currentBenefit) {
        setIsDefaultCalculation(true);
        setMortgagesList(
          [...flat.benefits.cash, ...flat.benefits.mortgage, ...flat.benefits.installment].filter(
            item => !item.name.toLowerCase().includes('тест'),
          ),
        );
      }
    }
    return () => clearTimeout(timeoutId);
  }, [flat, isEqualizer, benefitIdentificator, firstPaymentCommitted, termCommitted, maxTerm, minTerm]);

  const formatDate = isoString => {
    const date = new Date(isoString);

    const optionsDate = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };

    const optionsTime = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };

    const formattedDate = date.toLocaleDateString('ru-RU', optionsDate);
    const formattedTime = date.toLocaleTimeString('ru-RU', optionsTime);

    return `${formattedDate} ${formattedTime}`;
  };

  useEffect(() => {
    if (!activeProject?.blockId) {
      return;
    }

    if (!isEqualizer) {
      handleSetPendingStatus();
      fetchMortgagesPrice(activeProject.blockId)
        .then(result => {
          handleSetLoadedStatus();
          setMortgagesList(result);
        })
        .catch(() => {
          handleSetErrorStatus();
        });
    }

    const eventSource = new EventSource(
      `${DOMAIN_RELATIVE}/api/sse/flat/mortgage/list?blockId=${activeProject.blockId}`,
    );

    eventSource.addEventListener(`v2/flat/mortgage/list-${activeProject.blockId}`, event => {
      try {
        const eventData = JSON.parse(event.data);
        const eventTime = eventData.time;
        const eventId = event.lastEventId;
        const formattedEventTime = formatDate(eventTime);

        if (eventId !== lastEventId) {
          dispatch(setIsBenefitsUpdated(true));
          dispatch(setBenefitsUpdateTime(formattedEventTime));
          setMortgagesList(eventData.data);
          setLastEventId(eventId);
          localStorage.setItem('lastEventId', eventId);
        }
      } catch (error) {
        console.error('Ошибка при парсинге данных:', error);
      }
    });

    return () => {
      eventSource.close();
    };
  }, [
    activeProject?.blockId,
    handleSetErrorStatus,
    handleSetPendingStatus,
    handleSetLoadedStatus,
    isEqualizer,
    lastEventId,
  ]);

  useEffect(() => {
    handleGetCalcData();
  }, [
    currentBenefit,
    firstPaymentCommitted,
    termCommitted,
    flatPrice,
    minFirstPayment,
    maxFirstPayment,
    handleGetCalcData,
  ]);
  useEffect(() => {
    if (!activeProject?.url) {
      setFlats([]);
      return;
    }
    handleSetPendingStatus();
    fetchFlats(activeProject.url)
      .then(result => {
        handleSetLoadedStatus();
        const filteredFlats =
          result?.flats ||
          []
            .filter(flat => {
              return (
                flat.status === 'free' && (pathName.includes('calculator') ? true : flat.has_advertising_price !== 1)
              );
            })
            .sort((a, b) => {
              if (Number.parseFloat(a.number) > Number.parseFloat(b.number)) {
                return 1;
              }
              if (Number.parseFloat(a.number) < Number.parseFloat(b.number)) {
                return -1;
              }
              return 0;
            });
        setFlats(filteredFlats);
        setBulks(result.bulks);
      })
      .catch(() => {
        handleSetErrorStatus('Error in flats');
      });
  }, [activeProject, handleSetErrorStatus, handleSetPendingStatus, handleSetLoadedStatus, uniqueId]);

  useEffect(() => {
    if (
      (isEqualizer && firstPaymentCommitted < currentBenefit?.mortgage?.minPrice) ||
      firstPaymentCommitted > currentBenefit?.mortgage?.maxPrice
    ) {
      handleChangeFirstPaymentCommitted(currentBenefit?.mortgage?.minPrice);
    }
    //НЕ УБИРАТЬ!!!
    //в данном случае нам важно следить только за изменением бенефита, а не за первым платежом
    //если есть идеи, как сделать лучше - welcome)
  }, [
    currentBenefit?.mortgage?.minPrice,
    currentBenefit?.mortgage?.maxPrice,
    isEqualizer,
    handleChangeFirstPaymentCommitted,
  ]);
  useEffect(() => {
    setPriceWithMargin(calcData?.priceWithMargin || currentBenefit?.benefitPrice);
  }, [calcData, currentBenefit]);
  const sections = useMemo(() => {
    const arr = [];

    for (const i of flats) {
      if (!arr.includes(i.section?.number.toString()) && i.section?.number) {
        arr.push(i.section?.number.toString());
      }
    }
    return arr;
  }, [flats]);

  const houses = useMemo(() => {
    const sortedHouseNames = [...(bulks || [])]?.sort((a, b) => a.number - b.number).map(item => item.houseName);

    return [...new Set(sortedHouseNames)];
  }, [bulks]);
  const bulksNumbers = useMemo(() => {
    const arr = [];
    const bulksFiltered = house && house !== 'null' ? bulks?.filter(i => i.houseName === house) : bulks || [];
    for (const i of bulksFiltered) {
      if (!arr.includes(i.name) && i.name) {
        arr.push(i.name);
      }
    }
    return arr.sort();
  }, [bulks, house]);

  //данные функции нужны для сброса данных, если вдруг одно из полей изменено
  const projectHasChanged = () => {
    handleChangeHouse('');
    houseHasChanged();
    handleChangeTerm();
  };

  const houseHasChanged = () => {
    handleChangeSection('');
    handleChangeBulk('');
    sectionOrBulkHasChanged();
  };

  const sectionOrBulkHasChanged = () => {
    handleChangeFlat('');
    flatHasChanged();
  };

  const flatHasChanged = () => {
    handleChangeBenefit();
  };

  const getCurrentBenefitFormatted = benefit => {
    if (isEqualizer) {
      return { ...benefit, value: benefit?.title || '' };
    } else {
      return benefit;
    }
  };
  return {
    activeProject,
    section,
    sections: sections.map(section => String(section)),
    handleChangeSection,
    house,
    houses,
    handleChangeHouse,
    bulk,
    bulks: bulksNumbers,
    handleChangeBulk,
    handleChangeActiveProjectId,
    activeProjectId,
    flats: flatsNumbers,
    flatsData: flats,
    flat: flatNumber,
    flatData: flat,
    handleChangeFlat,
    benefit: getCurrentBenefitFormatted(benefit),
    benefits: changeOriginalBenefitsForSelect(benefits, uniqueId),
    handleChangeBenefit,
    benefitIdentificator,
    firstPayment,
    handleChangeFirstPayment,
    handleChangeFirstPaymentCommitted,
    term,
    termCommitted,
    handleChangeTerm,
    handleChangeTermCommitted,
    handleSetMinFirstPayment,
    isSetMinFirstPaymentChecked,
    handleSwitchValue,
    isPercent,
    percentFirstPayment,
    minFirstPayment,
    maxFirstPayment,
    flatPrice,
    handleChangePercentValue,
    priceWithMargin,
    calcData,
    projectHasChanged,
    houseHasChanged,
    sectionOrBulkHasChanged,
    flatHasChanged,
    mortgagesList,
    firstPaymentCommitted,
    currentBenefit,
    maxTerm,
    minTerm,
    benefitNotFound,
    isDefaultCalculation,
    lotSelectedFlat,
  };
};
