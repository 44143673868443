export const changeOriginalBenefitsForSelect = (benefits, calculatorType = 'price') => {
  return benefits.map(benefit => ({
    title: benefit?.name,
    id: String(benefit?.id),
    value: String(calculatorType === 'LotEqualizer' ? benefit?.name : benefit?.id),
  }));
};

export const formatEarlyPayments = ({ earlyPayments, earlyPaymentsType }) => {
  return earlyPayments?.map(({ sum, monthNumber }) => ({
    amount: sum || 0,
    month: monthNumber || 0,
    type: earlyPaymentsType || 'reduceTerm',
  }));
};

export const getSortedBulks = bulks => {
  return bulks.sort((a, b) => {
    // достаем из корпуса номер
    const extractNumber = str => {
      const match = str.match(/\d+(\.\d+)?/);
      return match ? parseFloat(match[0]) : 0;
    };
    return extractNumber(a) - extractNumber(b);
  });
};

export const getFlatsOptions = flats => {
  return flats?.sort((a, b) => a.number - b.number)?.map(flat => ({ id: flat?.id, value: flat?.number }));
};
