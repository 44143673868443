import { useEffect, useState } from 'react';
import { useDebouncedFunction } from '../../../hooks/useDebouncedFunction';

export const useGetGraceTerm = uniqueId => {
  const storageName = `graceTerm${uniqueId}`;
  const [graceTermForDisplay, setGraceTermForDisplay] = useState(1);
  const [graceTerm, setGraceTerm] = useState(1);
  const setGraceTermDebounced = useDebouncedFunction(setGraceTerm, 600);

  const onChangeGraceTerm = term => {
    setGraceTermForDisplay(term);
    setGraceTermDebounced(term);
    sessionStorage.setItem(storageName, term);
  };

  useEffect(() => {
    const graceTerm = sessionStorage.getItem(storageName);
    if (graceTerm) {
      setGraceTermForDisplay(Number(graceTerm));
      setGraceTerm(Number(graceTerm));
    }
  }, []);

  return {
    graceTermForDisplay,
    graceTerm,
    onChangeGraceTerm,
  };
};
