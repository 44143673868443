import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userRolesSelector } from '../../entities/AuthSlice';
import RoleCard from '../../features/role-card/RoleCard';
import { USER_TOOLS } from '../../utils/constants';
import ModalAuth from '../../widgets/modal-auth/ModalAuth';
import { Colors } from '../../assets/mui-styles/Colors';
import { styled } from '@mui/system';

const userRolesDefault = USER_TOOLS.map(i => i.role);

const AuthPage = () => {
  const [userRoleMatch, setUserRoleMatch] = useState();

  const user = JSON.parse(localStorage.getItem('user'));
  const isUser = Boolean(user?.id && user.firstName && user.email);
  const userStatusValid = user?.agencyStatus !== 'blocked';
  const userRoles = useSelector(userRolesSelector);

  useEffect(() => {
    if (!userRoles || userRoles.length === 0) return;

    const userRole = userRolesDefault.filter(i => {
      if (Array.isArray(i)) {
        return userRoles.some(role => i.includes(role));
      }
      return userRoles.includes(i);
    });

    setUserRoleMatch(userRole);
  }, [userRoles]);

  if (!isUser) {
    return <ModalAuth />;
  }
  return (
    <StyledContent>
      <StyledContainer>
        <StyledTitle>Выбор инструмента</StyledTitle>

        {userRoleMatch && userRoleMatch.length === 0 && !userRoles.includes('admin') && (
          <StyledPlug>Для вашей роли нет доступных инструментов</StyledPlug>
        )}

        {((userRoleMatch && userRoleMatch.length > 0) || userRoles.includes('admin')) && userStatusValid && (
          <StyledLinks>
            {USER_TOOLS.map(i => {
              return <RoleCard key={i.id} item={i} onlyMobile={i.onlyMobile} />;
            })}
          </StyledLinks>
        )}
      </StyledContainer>
    </StyledContent>
  );
};

export default AuthPage;

const StyledContent = styled('div')({
  minHeight: 'calc(100vh - 90px)',
  background: Colors.additional,
});

const StyledContainer = styled('div')({
  padding: 'calc(100 / 1440 * 100vw) 10vw',
});

const StyledTitle = styled('h1')({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: 'calc(60 / 1440 * 100vw)',
  lineHeight: '120%',
  letterSpacing: '-0.5px',
});

const StyledLinks = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: 'calc(24 / 1440 * 100vw)',
  marginTop: 'calc(60 / 1440 * 100vw)',
});

const StyledPlug = styled('div')({
  marginTop: '60px',
  fontSize: '24px',
});
