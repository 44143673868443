import { Box, Card, InputAdornment, Stack, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Colors } from '../../assets/mui-styles/Colors';
import { toFormat } from '../../utils/utils';
import { initialProjects } from './calculator-logic/initialProjects';
import { CustomInput } from './ui/CustomInput';
import { CustomInputWithSlider } from './ui/CustomInputWithSlider';
import { CustomSelect } from './ui/CustomSelect';
import { CustomSwitch } from './ui/CustomSwitch';
import {
  NumberSumFormatCustom,
  PercentFormatCustom,
  NumberSumFormatDefault,
} from './ui/InputComponentsForInputWithSlider';

export const CalculatorForPrice = ({
  activeProject,
  handleChangeActiveProjectId,
  benefit,
  benefits,
  handleChangeBenefit,
  handleChangeFirstPaymentCommitted,
  handleChangeFirstPayment,
  firstPayment,
  term,
  handleChangeTerm,
  handleChangeTermCommitted,
  isSetMinFirstPaymentChecked,
  handleSetMinFirstPayment,
  percentFirstPayment,
  isPercent,
  handleChangePercentValue,
  handleSwitchValue,
  maxFirstPayment,
  minFirstPayment,
  priceWithMargin,
  benefitGroup,
  groups,
  handleChangeBenefitGroup,
  flatPrice,
  handleChangeFlatPrice,
  benefitGroupHasChanged,
  projectHasChanged,
  minFlatPrice,
  maxFlatPrice,
  maxTerm,
  minTerm,
  fetchStatus,
}) => {
  const [errorFirstPayment, setErrorFirstPayment] = useState('');
  const [errorTerm, setErrorTerm] = useState('');
  const [errorFlatPrice, setErrorFlatPrice] = useState('');
  useEffect(() => {
    if (firstPayment < minFirstPayment) {
      setErrorFirstPayment('Увеличьте сумму первого взноса');
    } else if (firstPayment > maxFirstPayment) {
      setErrorFirstPayment('Уменьшите сумму первого взноса');
    } else {
      setErrorFirstPayment('');
    }
  }, [firstPayment, maxFirstPayment, minFirstPayment]);

  useEffect(() => {
    if (maxFlatPrice < minFlatPrice || Number(flatPrice) === 0) {
      setErrorFlatPrice('Увеличьте стоимость квартиры');
    } else {
      setErrorFlatPrice('');
    }
  }, [maxFlatPrice, minFlatPrice, flatPrice]);

  useEffect(() => {
    if (term < minTerm) {
      setErrorTerm('Увеличьте срок кредита');
    } else if (term > maxTerm) {
      setErrorTerm('Уменьшите срок кредита');
    } else {
      setErrorTerm('');
    }
  }, [maxTerm, minTerm, term]);

  const isFlatPRice = flatPrice !== 'undefined' && Number(flatPrice) !== 0;
  return (
    <StyledCard>
      <StyledTitle variant='h3'>Рассчитать платеж</StyledTitle>
      <StyledBox>
        <CustomSelect
          label='Выберите проект'
          items={initialProjects}
          currentItem={activeProject}
          handleChangeCurrentItem={value => {
            projectHasChanged();
            handleChangeActiveProjectId(value);
          }}
        />
        {groups?.length > 0 && (
          <CustomSelect
            label='Выберите группировку'
            items={groups}
            currentItem={benefitGroup}
            handleChangeCurrentItem={value => {
              benefitGroupHasChanged();
              handleChangeBenefitGroup(value);
            }}
          />
        )}

        {benefits.length > 0 && benefitGroup?.id !== '' && (
          <CustomSelect
            label='Выберите программу'
            handleChangeCurrentItem={value => handleChangeBenefit(value)}
            currentItem={benefit}
            items={benefits}
          />
        )}
        {(!benefits || benefits?.length <= 0) && (
          <>
            {!groups || (groups?.length <= 0 && <p></p>)}
            <Typography color={'red'}>Для выбранного проекта нет программ</Typography>
          </>
        )}
        {benefits.length > 0 && benefitGroup?.id !== '' && (
          <CustomInput
            helperText={errorFlatPrice}
            error={maxFlatPrice < minFlatPrice || Number(flatPrice) === 0}
            valueInput={flatPrice}
            endAdornment={<InputAdornment position='end'>₽</InputAdornment>}
            label='Стоимость квартиры'
            inputComponent={NumberSumFormatDefault}
            onChangeInput={handleChangeFlatPrice}
            fetchStatus={fetchStatus}
          />
        )}

        {benefits.length > 0 && benefitGroup?.id !== '' && !errorFlatPrice && isFlatPRice && benefit && (
          <>
            <CustomInputWithSlider
              helperText={errorFirstPayment}
              error={firstPayment < minFirstPayment || firstPayment > maxFirstPayment}
              onChangeInput={isPercent ? handleChangePercentValue : handleChangeFirstPaymentCommitted}
              onChangeSlider={handleChangeFirstPayment}
              valueSlider={firstPayment}
              valueInput={isPercent ? percentFirstPayment : firstPayment}
              label='Сумма первоначального взноса'
              min={minFirstPayment}
              max={maxFirstPayment}
              minValueText={
                isPercent
                  ? `${Math.round(((minFirstPayment * 100) / priceWithMargin) * 10) / 10}%`
                  : toFormat(minFirstPayment)
              }
              maxValueText={
                isPercent
                  ? `${Math.round(((maxFirstPayment * 100) / priceWithMargin) * 10) / 10}%`
                  : toFormat(maxFirstPayment)
              }
              inputComponent={isPercent ? PercentFormatCustom : NumberSumFormatCustom}
              endAdornment={
                <InputAdornment position='end'>
                  {isPercent ? toFormat(firstPayment) : `${percentFirstPayment} %`}
                </InputAdornment>
              }
              onChangeCommitted={handleChangeFirstPaymentCommitted}
            />
            <CustomInputWithSlider
              helperText={errorTerm}
              error={term < minTerm || term > maxTerm}
              onChange={handleChangeTerm}
              onChangeCommitted={handleChangeTermCommitted}
              onChangeInput={handleChangeTermCommitted}
              value={term}
              label='Срок кредита'
              min={minTerm}
              max={maxTerm}
              minValueText={minTerm}
              maxValueText={maxTerm}
              inputType='number'
              fetchStatus={fetchStatus}
            />
          </>
        )}
      </StyledBox>

      {benefits.length > 0 && benefitGroup?.id !== '' && !errorFlatPrice && isFlatPRice && benefit && (
        <SwitchStack>
          <CustomSwitch onClick={handleSwitchValue} checked={isPercent} label='Сумма первого взноса, %' />
          <CustomSwitch
            onClick={handleSetMinFirstPayment}
            checked={isSetMinFirstPaymentChecked}
            label='Установить минимальный первый взнос'
          />
        </SwitchStack>
      )}
    </StyledCard>
  );
};

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  background: Colors.additional,
  border: '1px solid',
  borderColor: Colors.superLightGrey,
  borderTopColor: 'transparent',
  padding: '24px',
});

const StyledTitle = styled(Typography)({
  margin: '0 0 20px',
});

const StyledBox = styled(Box)({
  display: 'grid',
  gap: '24px',
  gridTemplateColumns: 'minmax(0, 1fr) 13vw',
});

const SwitchStack = styled(Stack)({
  alignItems: 'flex-start',
});
