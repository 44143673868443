import { useEffect, useState } from 'react';
import { Card, styled } from '@mui/material';
import { Colors } from '../../../assets/mui-styles/Colors';
import { MonthPaymentRowHeader } from './components/MonthPaymentRow';
import { YearsRow } from './components/YearsRow';
import { MonthlyPaymentBody } from './components/MonthlyPaymentBody';
import { PaymentsScheduleTitle } from './components/PaymentsScheduleTitle';

export const PaymentsSchedule = ({ payments = [] }) => {
  const [activeYear, setActiveYear] = useState(1);

  useEffect(() => {
    setActiveYear(1);
  }, [payments]);

  return (
    <StyledCard sx={{ background: Colors.additional }}>
      <PaymentsScheduleTitle />
      <YearsRow payments={payments} activeYear={activeYear} onClickYear={setActiveYear} />
      <MonthPaymentRowHeader />
      <MonthlyPaymentBody payments={payments} activeYear={activeYear} />
    </StyledCard>
  );
};

const StyledCard = styled(Card)({
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: '21px 24px',
  border: `1px solid ${Colors.superLightGrey} !important`,
});
