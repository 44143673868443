import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { styled } from '@mui/system';

export const CalculatorFreeButton = ({ children, type = 'email', onClick }) => {
  return (
    <>
      <StyledButton onClick={onClick}>
        {children}
        {type === 'email' && <MailOutlineIcon />}
        {type === 'pdf' && <PictureAsPdfOutlinedIcon />}
      </StyledButton>
    </>
  );
};

const StyledButton = styled('button')({
  padding: '8px 22px',
  backgroundColor: '#e4e5df',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  fontSize: '15px',
  lineHeight: '26px',
  fontWeight: 500,
  color: '#302e2d',
  fontFamily: "'Unica77LLCyr-Medium', sans-serif",
});
