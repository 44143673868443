import { useEffect, useMemo, useState } from 'react';

export const useSelectFlat = (flats, bulks, { section, bulk, house }, currProject, uniqueSuffix, isLotTab = false) => {
  const flatName = `currFlat${uniqueSuffix}`;
  const foundBulk = bulk ? bulks?.find(i => bulk === i.name) : null;
  const foundHouse = house ? bulks?.find(i => house === i.houseName) : null;
  const [flatNumber, setFlatNumber] = useState(sessionStorage.getItem(flatName));
  const [lotSelectedFlat, setLotSelectedFlat] = useState(
    JSON.parse(sessionStorage.getItem(`lotSelectedFlat${uniqueSuffix}`)) || null,
  );
  const handleChangeFlat = value => {
    if (!isLotTab) {
      setFlatNumber(value);
      sessionStorage.setItem(flatName, value);
    } else {
      setLotSelectedFlat(value);
      sessionStorage.setItem(`lotSelectedFlat${uniqueSuffix}`, JSON.stringify(value));
    }
  };

  const houseIds = useMemo(() => {
    if (!foundHouse) return [];
    return bulks
      .filter(i => i.houseName === foundHouse?.houseName)
      .map(item => {
        return item.id;
      });
  }, [bulks, foundHouse]);

  const flatsNumbers = useMemo(() => {
    if (!flats) return [];

    if (currProject?.id === 1 && section) {
      return flats.filter(flat => Number(flat.section.number) === Number(section));
    }

    if (foundBulk) {
      return flats.filter(flat => flat.bulk_id === foundBulk.id);
    }

    if (foundHouse) {
      return flats.filter(flat => houseIds.includes(flat.bulk_id));
    }

    return flats;
  }, [flats, section, currProject, foundBulk, foundHouse, houseIds]);

  useEffect(() => {
    const flatFromSS = sessionStorage.getItem(flatName);
    if (flatFromSS && flatFromSS !== 'undefined') {
      setFlatNumber(flatFromSS);
    }
  }, [flatName]);

  const getFlatForReturn = () => {
    if (isLotTab) {
      return flats.find(flat => String(flat?.id) === String(lotSelectedFlat?.id));
    } else {
      return flats.find(flat => String(flat.number) === String(flatNumber));
    }
  };

  return {
    flatsNumbers,
    flatNumber,
    handleChangeFlat,
    flat: getFlatForReturn(),
    lotSelectedFlat,
  };
};
