import { Box, ThemeProvider, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { Colors } from '../../../assets/mui-styles/Colors';
import { primaryTheme } from '../../../assets/mui-styles/GlobalStyles';
import { getFavoriteFlats } from '../../../entities/selectors/selectors';
import { CalculatorForLot } from '../../../widgets/calculator/CalculatorForLot';
import { CalculatorForPrice } from '../../../widgets/calculator/CalculatorForPrice';
import { CalculatorForFree } from '../../../widgets/calculator/CalculatorForFree';
import { EqualizerFlatsSliderNew } from '../../equalizer-page-new/equalzier-flats-slider/EqualizerFlatsSlider';
import { calculatorFreeTab } from '../../../utils/feature-flags';

export const CalculatorTabs = ({
  propsForCalculatorByLot,
  propsForCalculatorByPrice,
  propsForCalculatorByFreeNew,
  statusSetters,
  activeTab,
  handleChangeActiveTab,
  meetingId,
  isEqualizer,
  fetchStatus,
}) => {
  const favoriteFlats = useSelector(getFavoriteFlats);

  return (
    <StyledContent>
      <StyledSwitches threeColumns={true}>
        <StyledSwitch type={'button'} onClick={() => handleChangeActiveTab('lot')} isActive={activeTab === 'lot'}>
          Расчет по лоту
        </StyledSwitch>
        <StyledSwitch type={'button'} onClick={() => handleChangeActiveTab('price')} isActive={activeTab === 'price'}>
          Расчет по прайс-цене
        </StyledSwitch>
        {calculatorFreeTab && (
          <StyledSwitch type={'button'} onClick={() => handleChangeActiveTab('free')} isActive={activeTab === 'free'}>
            Свободный расчет
          </StyledSwitch>
        )}
      </StyledSwitches>

      {isEqualizer && activeTab === 'lot' && favoriteFlats?.length > 0 && meetingId && (
        <ThemeProvider theme={primaryTheme}>
          <StyledSliderWrapper>
            <EqualizerFlatsSliderNew
              favoriteFlats={favoriteFlats}
              propsForFlatCards={activeTab === 'lot' ? propsForCalculatorByLot : propsForCalculatorByPrice}
            />
          </StyledSliderWrapper>
        </ThemeProvider>
      )}

      {activeTab === 'lot' && (
        <CalculatorForLot
          {...propsForCalculatorByLot}
          statusSetters={statusSetters}
          isEqualizer={isEqualizer}
          meetingId={meetingId}
          fetchStatus={fetchStatus}
        />
      )}

      {activeTab === 'free' && (
        <CalculatorForFree fetchStatus={fetchStatus} isEqualizer={isEqualizer} {...propsForCalculatorByFreeNew} />
      )}

      {activeTab === 'price' && (
        <CalculatorForPrice {...propsForCalculatorByPrice} statusSetters={statusSetters} fetchStatus={fetchStatus} />
      )}
    </StyledContent>
  );
};

const StyledContent = styled('div')({
  width: '100%',
});

const StyledSwitches = styled('div')(({ threeColumns }) => ({
  display: 'grid',
  gridTemplateRows: '57px',
  gridTemplateColumns: threeColumns ? '1fr 1fr 1fr' : '1fr 1fr',
}));

const StyledSwitch = styled('button')(({ isActive }) => ({
  color: Colors.secondary,
  fontSize: '16px',
  lineHeight: '150%',
  border: `1px solid ${Colors.superLightGrey}`,
  borderRadius: '4px 4px 0 0',
  background: isActive ? Colors.additional : 'transparent',
  borderBottom: isActive ? 'none' : `1px solid ${Colors.superLightGrey}`,
}));

const StyledWrapperStealth = styled('div')({
  position: 'absolute',
  width: '0',
  height: '0',
  overflow: 'hidden',
});

const StyledSliderWrapper = styled(Box)({
  padding: 'calc(30 / 1440 * 100vw) calc(24 / 1440 * 100vw) 0 !important',
  background: Colors.additional,
  border: '1px solid',
  borderColor: Colors.superLightGrey,
  borderTopColor: 'transparent',
  borderBottomColor: 'transparent',
});
