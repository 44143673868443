import { useEffect, useState } from 'react';

export const useGracePeriodSwitcher = uniqueId => {
  const storageName = `graceSwitcher${uniqueId}`;
  const [isGracePeriod, setIsGracePeriod] = useState(false);

  const toggleGracePeriod = () => {
    setIsGracePeriod(!isGracePeriod);
    sessionStorage.setItem(storageName, !isGracePeriod);
  };

  useEffect(() => {
    const storageValue = sessionStorage.getItem(storageName);
    if (storageValue === 'true') setIsGracePeriod(true);
  }, []);

  return { isGracePeriod, toggleGracePeriod };
};
