import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Typography, Box, InputAdornment, styled } from '@mui/material';
import { Colors } from '../../../assets/mui-styles/Colors';
import { CustomInput } from './CustomInput';
import { NumberSumFormatDefault } from './InputComponentsForInputWithSlider';

export const EarlyPaymentCard = ({ payment, index, deletePayment, onChangeEarlyPayment, fetchStatus }) => {
  const handleSumChange = value => {
    onChangeEarlyPayment({
      id: payment?.id,
      key: 'sum',
      newValue: value === 0 ? '' : value,
    });
  };

  const handleMonthChange = value => {
    if (Array.from(String(value))?.length > 3) return;
    if (isNaN(value)) return;
    onChangeEarlyPayment({
      id: payment?.id,
      key: 'monthNumber',
      newValue: value === 0 ? '' : value,
    });
  };

  return (
    <StyledBox key={payment?.id}>
      <StyledTypography>{`Досрочный платеж ${index + 1}`}</StyledTypography>
      <StyledInputsBox>
        <Box width={'40%'}>
          <CustomInput
            valueInput={payment?.sum}
            endAdornment={<InputAdornment position='end'>₽</InputAdornment>}
            label='Сумма платежа'
            inputComponent={NumberSumFormatDefault}
            onChangeInput={handleSumChange}
            fetchStatus={fetchStatus}
          />
        </Box>
        <Box width={'25%'}>
          <CustomInput
            valueInput={payment?.monthNumber}
            endAdornment={
              <InputAdornment position='end'>
                <StyledBorderColorIcon />
              </InputAdornment>
            }
            label='Номер месяца'
            onChangeInput={handleMonthChange}
            noDebounce={true}
            fetchStatus={fetchStatus}
          />
        </Box>
        <StyledDeleteOutlineIcon onClick={() => deletePayment(payment?.id)} />
      </StyledInputsBox>
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  background: Colors.additional,
  borderColor: Colors.superLightGrey,
  borderTopColor: 'transparent',
});

const StyledInputsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: '20px',
  gap: '20px',
  alignItems: 'center',
});

const StyledTypography = styled(Typography)({
  fontSize: '20px',
  fontWeight: '500',
});

const StyledBorderColorIcon = styled(BorderColorIcon)({
  width: '20px',
  height: '20px',
  color: '#302E2D61',
});

const StyledDeleteOutlineIcon = styled(DeleteOutlineIcon)({
  width: '20px',
  height: '20px',
  cursor: 'pointer',
});
