import { StyledTypography } from './styled';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

export const ModalResetPasswordSuccessful = ({ handleCloseModals, emailForReset }) => {
  return (
    <>
      <StyledCloseIcon onClick={handleCloseModals} />
      <StyledTypography sx={{ textAlign: 'center' }}>Новый пароль отправлен на почту</StyledTypography>
      <StyledTypography sx={{ marginTop: '16px', textAlign: 'center', fontWeight: '600', lineHeight: '26.7px' }}>
        {emailForReset}
      </StyledTypography>
    </>
  );
};

const StyledCloseIcon = styled(CloseIcon)({
  position: 'absolute',
  top: '0px',
  right: '0px',
  cursor: 'pointer',
});
