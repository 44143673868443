import { useState } from 'react';
import { useDebouncedFunction } from '../../../hooks/useDebouncedFunction';
import { v4 as uuidv4 } from 'uuid';

export const useGetEarlyPayment = () => {
  const earlyPaymentOptions = [
    {
      id: 1,
      value: 'Платеж',
      title: 'Платеж',
      backendValue: 'reducePayment',
    },
    {
      id: 2,
      value: 'Срок',
      title: 'Срок',
      backendValue: 'reduceTerm',
    },
  ];

  const [earlyPaymentsForDisplay, setEarlyPaymentsForDisplay] = useState([]);
  const [earlyPayments, setEarlyPayments] = useState([]);

  const [earlyPaymentsType, setEarlyPaymentsType] = useState({
    id: 2,
    value: 'Срок',
    title: 'Срок',
    backendValue: 'reduceTerm',
  });

  const clearEarlyPayments = () => {
    setEarlyPaymentsForDisplay([]);
    setEarlyPayments([]);
  };

  const setEarlyPaymentsDebounced = useDebouncedFunction(setEarlyPayments, 600);
  // редактируем досрочный платеж
  const onChangeEarlyPayment = ({ id, key, newValue }) => {
    setEarlyPaymentsForDisplay(prev =>
      prev.map(payment => {
        if (payment.id === id) {
          return {
            ...payment,
            [key]: newValue,
            ['type']: earlyPaymentsType?.backendValue,
          };
        }
        return payment;
      }),
    );
    setEarlyPaymentsDebounced(prev =>
      prev.map(payment => {
        if (payment.id === id) {
          return {
            ...payment,
            [key]: newValue,
            ['type']: earlyPaymentsType?.backendValue,
          };
        }
        return payment;
      }),
    );
  };

  // выбираем тип всех платежей ('что уменьшить?')
  const onChangeEarlyPaymentsType = type => {
    const selectedType = earlyPaymentOptions.find(option => option.value === type);
    if (selectedType) {
      setEarlyPaymentsType(selectedType);
    }
  };

  const createEmptyPayment = () => {
    const id = uuidv4();
    setEarlyPaymentsForDisplay([...earlyPaymentsForDisplay, { id, sum: '', monthNumber: '' }]);
    setEarlyPaymentsDebounced([...earlyPaymentsForDisplay, { id, sum: '', monthNumber: '' }]);
  };

  const deletePayment = id => {
    setEarlyPaymentsForDisplay(prev => prev.filter(payment => payment.id !== id));
    setEarlyPaymentsDebounced(prev => prev.filter(payment => payment.id !== id));
  };

  return {
    earlyPayments,
    earlyPaymentOptions,
    earlyPaymentsForDisplay,
    onChangeEarlyPayment,
    createEmptyPayment,
    deletePayment,
    earlyPaymentsType,
    onChangeEarlyPaymentsType,
    clearEarlyPayments,
  };
};
