import { EstimationBlock } from '../../estimation-block/EstimationBlock';
import { Box, styled } from '@mui/system';

export const LotEstimationBlock = ({ propsForCalculatorByLot, fetchStatus, meetingId, isEqualizer, activeTab }) => {
  const checkIfDisplayLotEstimationBlock = () => {
    const {
      priceWithMargin,
      minFirstPayment,
      maxFirstPayment,
      benefit,
      term,
      maxTerm,
      minTerm,
      firstPaymentCommitted,
    } = propsForCalculatorByLot;

    const doesBenefitExist = Boolean(benefit);
    const isTermInRange = term >= minTerm && term <= maxTerm;
    const isPaymentInRange = firstPaymentCommitted <= maxFirstPayment && firstPaymentCommitted >= minFirstPayment;
    const isPriceWithMarginPositive = priceWithMargin > 0;
    return doesBenefitExist && isTermInRange && isPaymentInRange && isPriceWithMarginPositive;
  };

  const shouldDisplay = checkIfDisplayLotEstimationBlock();
  return (
    shouldDisplay &&
    ((isEqualizer && !propsForCalculatorByLot.isDefaultCalculation) || !isEqualizer) && (
      <StyledBox
        sx={{
          position: activeTab !== 'lot' ? 'absolute' : 'relative',
          width: activeTab !== 'lot' ? 0 : 'auto',
          overflow: activeTab !== 'lot' ? 'hidden' : 'visible',
        }}
      >
        <EstimationBlock
          isEqualizer={isEqualizer}
          {...propsForCalculatorByLot}
          fetchStatus={fetchStatus}
          meetingId={meetingId}
          isLotTab={activeTab === 'lot'}
        />
      </StyledBox>
    )
  );
};

const StyledBox = styled(Box)({
  height: '100%',
});
